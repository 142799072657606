import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba, lighten, darken } from 'polished'
import { Grid } from 'react-styled-flexboxgrid'
import { Icon } from '@muhsis/ui'
import { theme } from '../../theme'
import { Brand } from '../../components'

const ScHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 0;
  margin: 0;
`

const ScNav = styled.nav`
  & > a {
    border-radius: 0.25rem;
    color: ${theme.colors.text.default};
    margin-right: 0.2rem;
    padding: 0.65rem 1rem;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.15s, background-color 0.15s;
  }

  & > a.active {
    background-color: ${rgba(theme.palette.primary.base, 0.9)};
    color: #ebebeb;
    font-weight: 600;
  }

  & > a:hover {
    background-color: ${rgba(theme.palette.primary.base, 0.2)};
    color: ${theme.palette.primary.base};
  }

  & > a:last-child {
    margin-right: 0;
  }

  & > a > svg {
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
  }

  & > a:hover > svg {
    fill: ${theme.palette.primary.base} !important;
  }
`

const ScRegisterLink = styled.div`
  display: inline-block;

  & > a {
    cursor: pointer;
    display: block;
    background-color: ${theme.palette.primary.base};
    background-image: linear-gradient(
      to bottom,
      ${lighten(0.1, theme.palette.primary.base)},
      ${darken(0.1, theme.palette.primary.base)}
    );
    font-size: 0.95rem;
    text-decoration: none;
    padding: 0.575rem 1.25rem;
    border-radius: 0.25rem;
    line-height: 1.5;
    color: #fff;
    position: relative;
    top: -1px;

    &:hover {
      background-color: ${darken(0.1, theme.palette.primary.base)};
      background-image: none;
    }

    &:active {
      box-shadow: 0 0 0 3px ${rgba(theme.palette.primary.base, 0.4)},
        inset 0 0 0 1px rgba(66, 90, 112, 0.14),
        inset 0 1px 1px 0 rgba(66, 90, 112, 0.06);
    }
  }
`

class Header extends PureComponent {
  render() {
    return (
      <Grid>
        <ScHeader>
          <Brand />

          <ScNav>
            <Link to="/ozellikler" activeClassName="active">
              Özellikler
            </Link>
            <Link to="/fiyatlar" activeClassName="active">
              Fiyatlar
            </Link>
            <Link to="/guvenlik" activeClassName="active">
              Güvenlik
            </Link>
            <a
              href="https://destek.muhsis.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Destek
            </a>
          </ScNav>

          <ScNav>
            <a
              href="https://app.muhsis.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="lock" color="default" size={16} />
              Kullanıcı Girişi
            </a>

            <ScRegisterLink>
              <Link to="/uye-ol">Ücretsiz Dene</Link>
            </ScRegisterLink>
          </ScNav>
        </ScHeader>
      </Grid>
    )
  }
}

export default Header
