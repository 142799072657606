import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { rgba, lighten, darken } from 'polished'
import { Grid } from 'react-styled-flexboxgrid'
import { Icon } from '@muhsis/ui'

import { theme } from '../../theme'
import { Brand } from '../../components'

const ScHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 0;
  margin: 0;
`

const ScNav = styled.nav``

const ScLoginLink = styled.a`
  display: inline-block;
  cursor: pointer;
  background-color: ${theme.palette.primary.base};
  background-image: linear-gradient(
    to bottom,
    ${lighten(0.1, theme.palette.primary.base)},
    ${darken(0.1, theme.palette.primary.base)}
  );
  font-size: 0.95rem;
  text-decoration: none;
  padding: 0.575rem 1.25rem;
  border-radius: 0.25rem;
  line-height: 1.5;
  color: #fff;
  position: relative;
  text-decoration: none;
  top: -1px;
  margin-left: 1rem;

  &:hover {
    background-color: ${darken(0.1, theme.palette.primary.base)};
    background-image: none;
  }

  &:active {
    box-shadow: 0 0 0 3px ${rgba(theme.palette.primary.base, 0.4)},
      inset 0 0 0 1px rgba(66, 90, 112, 0.14),
      inset 0 1px 1px 0 rgba(66, 90, 112, 0.06);
  }

  & svg {
    position: relative;
    top: -2px;
    margin-right: 0.5rem;
  }
`

class HeaderAuth extends PureComponent {
  render() {
    return (
      <Grid fluid>
        <ScHeader>
          <Brand />

          <ScNav>
            Zaten hesabınız var mı?
            <ScLoginLink
              href="https://app.muhsis.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="lock" color="currentColor" size={16} />
              Kullanıcı Girişi
            </ScLoginLink>
          </ScNav>
        </ScHeader>
      </Grid>
    )
  }
}

export default HeaderAuth
