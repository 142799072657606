import axios from 'axios'

/**
 * Starts membership workflow for given email
 * @param {string} email
 */
export async function sendMembershipRequest(email) {
  const result = await axios.post('https://api.muhsis.com/api/v1/register', {
    Email: email,
  })
  return result
}
