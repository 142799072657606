import React, { PureComponent } from 'react'
import { Heading, Paragraph, Pane } from '@muhsis/ui'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { theme } from '../../theme'
import { ImageFluid } from '../../components'

class FeatureMain extends PureComponent {
  render() {
    return (
      <Pane paddingY="2.5rem">
        <Grid>
          <Row>
            {this.props.orientation === 'left' && (
              <>
                <Col sm={12} md={6}>
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ImageFluid src={this.props.image} alt={this.props.title} />
                  </Pane>
                </Col>

                <Col sm={12} md={6}>
                  <Pane
                    marginLeft="1.5rem"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    height="100%"
                  >
                    <Heading
                      size={400}
                      color={theme.palette.primary.base}
                      marginBottom="0.5rem"
                    >
                      {this.props.suptitle}
                    </Heading>
                    <Heading size={700} marginBottom="1rem">
                      {this.props.title}
                    </Heading>
                    <Paragraph marginBottom="1.5rem">
                      {this.props.description}
                    </Paragraph>

                    {this.props.children}
                  </Pane>
                </Col>
              </>
            )}

            {this.props.orientation === 'right' && (
              <>
                <Col sm={12} md={6}>
                  <Pane
                    marginRight="1.5rem"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    height="100%"
                  >
                    <Heading
                      size={300}
                      color={theme.palette.primary.base}
                      marginBottom="0.5rem"
                    >
                      {this.props.suptitle}
                    </Heading>
                    <Heading size={700} marginBottom="1rem">
                      {this.props.title}
                    </Heading>
                    <Paragraph marginBottom="1.5rem">
                      {this.props.description}
                    </Paragraph>

                    {this.props.children}
                  </Pane>
                </Col>

                <Col sm={12} md={6}>
                  <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ImageFluid src={this.props.image} alt={this.props.title} />
                  </Pane>
                </Col>
              </>
            )}
          </Row>
        </Grid>
      </Pane>
    )
  }
}

export default FeatureMain

// ;<Grid>
//   <Row>
//     <Col
//       xs={12}
//       md={6}
//       order={{ xs: 2, md: 1 }}
//       display="flex"
//       alignItems="center"
//       justifyContent="center"
//     >
//       <Box textAlign="left" maxWidth={400}>
//         <Feature>
//           <hgroup>
//             <h4>BELGE YÖNETİMİ</h4>
//             <h2>Fatura ve İrsaliyelerinizi Yönetin</h2>
//           </hgroup>

//           <p>
//             Fatura, irsaliye, sipariş, yurt dışı fatura, proforma fatura,iade
//             fatura ve stok fişi gibi tüm alış ve satış belgelerini
//             yönetebilirsiniz. Gelişmiş filtreleme sistemi ile aradığınız belgeye
//             rahatlıkla ulaşırsınız. Belgeler arası dönüştürme yapmak çok
//             kolaydır.
//           </p>

//           <Row mt={3}>
//             <Col>
//               <ul>
//                 <li>
//                   <Check size="24" /> Tevkifat uygulama
//                 </li>

//                 <li>
//                   <Check size="24" /> Gelişmiş filtreleme
//                 </li>

//                 <li>
//                   <Check size="24" /> Fatura paylaşımı
//                 </li>
//               </ul>
//             </Col>

//             <Col>
//               <ul>
//                 <li>
//                   <Check size="24" /> Yazdırılabilir şablonlar
//                 </li>

//                 <li>
//                   <Check size="24" /> Belge dönüşümleri
//                 </li>

//                 <li>
//                   <Check size="24" /> İndirim yönetimi
//                 </li>
//               </ul>
//             </Col>
//           </Row>
//         </Feature>
//       </Box>
//     </Col>

//     <Col xs={12} md={6} order={{ xs: 1, md: 2 }}>
//       <Box textAlign="center">
//         <ImageFluid src={f1} alt="Muhsis, belge yönetimi" />
//       </Box>
//     </Col>
//   </Row>
// </Grid>
