import React, { PureComponent } from 'react'
import { Pane, Heading, Paragraph } from '@muhsis/ui'

class FeatureItem extends PureComponent {
  render() {
    return (
      <Pane textAlign="center">
        <img src={this.props.image} alt={this.props.title} height="50" />

        <Heading
          size={500}
          marginTop="2rem"
          marginBottom="0.5rem"
          fontWeight={600}
        >
          {this.props.title}
        </Heading>
        <Paragraph>{this.props.description}</Paragraph>
      </Pane>
    )
  }
}

export default FeatureItem
