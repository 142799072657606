import React, { PureComponent } from 'react'
import { Paragraph, Pane } from '@muhsis/ui'
import { Row, Col } from 'react-styled-flexboxgrid'

import { SpotlightHeading } from '../'
import { Card } from '../../components'
import { IconAt } from '../../assets/icons'

class Support extends PureComponent {
  render() {
    return (
      <Pane paddingY="5rem">
        <SpotlightHeading
          icon="endorsed"
          title="Memnuniyetiniz Bizim İçin Çok Önemli"
          description="Muhsis'te tüm ekip olarak, müşterilerimizin memnuniyetine çok önem veriyoruz. Tüm soru, öneri ve şikayetlerinizi, istediğiniz zaman, uygulama içerisinden veya telefonla arayarak bizimle paylaşabilirsiniz. Destek ekibimiz, size severek yardımcı olacaktır."
        />

        <Pane maxWidth={600} marginX="auto" paddingY="2.5rem">
          <Row>
            <Col sm={6}>
              <Pane height="100%" display="flex" alignItems="stretch">
                <Card
                  icon="help"
                  title="Yardım & Destek Merkezi"
                  minHeight={160}
                >
                  <Paragraph>
                    Online yardım merkezimizi ziyaret ederek, sizin için
                    hazırladığımız{' '}
                    <a
                      href="https://destek.muhsis.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      makale ve kullanım videolarına
                    </a>{' '}
                    göz atabilirsiniz.
                  </Paragraph>
                </Card>
              </Pane>
            </Col>

            <Col sm={6}>
              <Pane height="100%" display="flex" alignItems="stretch">
                <Card icon="envelope" title="Bize Ulaşın">
                  <Paragraph>
                    Ekibimize <strong>destek<IconAt />muhsis.com</strong> adresine
                    e-posta göndererek, ya da <strong>0 850 233 5431</strong>{' '}
                    numaralı destek hattımızı arayarak ulaşabilirsiniz.
                  </Paragraph>
                </Card>
              </Pane>
            </Col>
          </Row>
        </Pane>
      </Pane>
    )
  }
}

export default Support
