import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Heading, Paragraph } from '@muhsis/ui'

const ScSpotlight = styled.section`
  text-align: center;
  padding: 2rem 0;
  margin: 0 auto;
  max-width: 700px;
`

class SpotlightLight extends PureComponent {
  render() {
    return (
      <ScSpotlight>
        <Heading size={900} marginBottom="1rem" fontWeight="700">
          {this.props.title}
        </Heading>
        {this.props.description && (
          <Paragraph size={500}>{this.props.description}</Paragraph>
        )}
      </ScSpotlight>
    )
  }
}

export default SpotlightLight
