import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Heading, Paragraph, Icon } from '@muhsis/ui'
import { theme } from '../../theme'

const ScSpotlight = styled.section`
  color: ${theme.palette.primary.base};
  text-align: center;
  padding: 0 0 2rem 0;
  margin: 0 auto;
  max-width: 500px;
`

class SpotlightHeading extends PureComponent {
  render() {
    return (
      <ScSpotlight>
        {this.props.icon && (
          <Icon icon={this.props.icon} color="currentColor" size={64} />
        )}

        <Heading
          size={700}
          marginBottom="0.5rem"
          marginTop="2rem"
          fontWeight="700"
        >
          {this.props.title}
        </Heading>
        {this.props.description && (
          <Paragraph size={500}>{this.props.description}</Paragraph>
        )}
      </ScSpotlight>
    )
  }
}

export default SpotlightHeading
