import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { Icon, Pane, Text } from '@muhsis/ui'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { lighten } from 'polished'

import { theme } from '../../theme'
import { Card } from '../../components'

const PricingTableHeader = styled.div`
  color: ${theme.palette.orange.base};
  font-size: 0.875rem;
  margin: 0 0 3rem 0;
  text-align: center;

  & > svg {
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
  }
`

const PricingTablePrice = styled.div`
  font-family: ${theme.fontFamilies.display};
  font-weight: 700;
  font-size: 3rem;
  margin: 3rem 0;
  padding: 0;
  line-height: 1;

  & > small {
    color: ${lighten(0.3, theme.colors.text.muted)};
    font-size: 1rem;
    margin-left: 0.5rem;
  }

  & > span {
    color: ${lighten(0.3, theme.colors.text.muted)};
    display: block;
    font-family: ${theme.fontFamilies.display};
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
    text-decoration: line-through;
  }
`

const PricingTableText = styled.p`
  color: ${theme.palette.neutral.base};
  font-size: 0.875rem;
  max-width: 200px;
  padding: 0.25rem;

  & strike {
    color: ${lighten(0.3, theme.colors.text.muted)};
  }

  & span {
    border-bottom: 1px solid ${theme.palette.primary.base};
    color: ${theme.palette.primary.base};
    margin-left: 0.25rem;
  }
`

class PricingTable extends PureComponent {
  render() {
    return (
      <Pane marginBottom="2rem" marginTop="3rem">
        <Grid>
          <Row>
            <Col sm={4}>
              <Card>
                <Text>1 Aylık</Text>
                <PricingTablePrice>
                  40.00 ₺<small>+ KDV</small>
                </PricingTablePrice>

                <PricingTableText>
                  Muhsis ile tanışma fiyatı. Dilediğiniz zaman daha uzun ve
                  <span>hesaplı bir pakete geçebilirsiniz.</span>
                </PricingTableText>
              </Card>
            </Col>

            <Col sm={4}>
              <Card isExclusive>
                <PricingTableHeader>
                  <Icon
                    icon="star"
                    marginLeft="0.5rem"
                    color="currentColor"
                    size={20}
                  />
                  En Avantajlı Paketimiz
                </PricingTableHeader>

                <Text>6 Aylık (Kampanyalı Fiyat)</Text>
                <PricingTablePrice>
                  <span>240.00 ₺</span>
                  60.00 ₺<small>+ KDV</small>
                </PricingTablePrice>

                <PricingTableText>
                  Kısa bir süre için, Muhsis'i 6 aylığına,{' '}
                  <strike>240.00 ₺</strike> yerine sadece{' '}
                  <span>60.00 ₺'ye</span> kullanın.
                </PricingTableText>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <Text>1 Yıllık (%15 İndirim)</Text>

                <PricingTablePrice>
                  <span>480.00 ₺</span>
                  408.00 ₺<small>+ KDV</small>
                </PricingTablePrice>

                <PricingTableText>
                  Muhsis'teki <span>en hesaplı paketimiz.</span> Uzun vadeli
                  planlama yapmak isteyenler için ideal.
                </PricingTableText>
              </Card>
            </Col>
          </Row>
        </Grid>
      </Pane>
    )
  }
}

export default PricingTable
