import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { rgba, lighten, darken } from 'polished'
import { Pane, Icon, Heading, Paragraph } from '@muhsis/ui'
import { Grid } from 'react-styled-flexboxgrid'
import { theme } from '../../theme'

const ScSpotlight = styled.section`
  background-color: ${theme.palette.primary.base};
  background-image: linear-gradient(
    135deg,
    ${darken(0.39, theme.palette.primary.base)} 0%,
    ${darken(0.3, theme.palette.primary.base)} 71%,
    ${darken(0.25, theme.palette.primary.base)} 100%
  );
  text-align: center;
  padding: 4rem 0;
  margin: 0;

  & a {
    color: ${theme.palette.neutral.light};
    text-decoration: none;
    transition: color 0.15s, border-color 0.15s;
  }

  & a > span {
    border-bottom: 1px solid ${theme.palette.neutral.light};
  }

  & a:hover {
    color: ${theme.palette.neutral.lightest};
  }
`

const ScRegisterLink = styled.div`
  display: inline-block;

  & > a {
    cursor: pointer;
    display: inline-block;
    background-color: ${theme.palette.green.base};
    background-image: linear-gradient(
      to bottom,
      ${lighten(0.1, theme.palette.green.base)},
      ${darken(0.1, theme.palette.green.base)}
    );
    text-decoration: none;
    padding: 0.75rem 2.5rem;
    font-size: 1.25rem;
    border-radius: 0.25rem;
    line-height: 1.5;
    color: #fff;

    &:hover {
      background-color: ${darken(0.1, theme.palette.green.base)};
      background-image: none;
    }

    &:active {
      box-shadow: 0 0 0 3px ${rgba(theme.palette.green.base, 0.4)},
        inset 0 0 0 1px rgba(66, 90, 112, 0.14),
        inset 0 1px 1px 0 rgba(66, 90, 112, 0.06);
    }
  }
`

class SpotlightDark extends PureComponent {
  render() {
    return (
      <ScSpotlight>
        <Grid>
          <Pane maxWidth={600} marginX="auto" textAlign="center">
            <Heading
              size={700}
              color={theme.palette.neutral.lightest}
              marginBottom="1rem"
              fontWeight={700}
            >
              Muhsis Üyeliğinizi Hemen Başlatın
            </Heading>
            <Paragraph color={theme.palette.neutral.light} marginBottom="2rem">
              Üye olun, ekibinizi Muhsis'e davet edin ve firmanızın finansal
              süreçlerini, sizin için hazırladığımız kullanımı kolay ve hızlı
              arayüzler ile kolaylıkla yönetin. İlk 30 gün ücretsiz.
            </Paragraph>

            <Pane marginBottom="1rem">
              <ScRegisterLink>
                <Link to="/uye-ol">Ücretsiz Dene</Link>
              </ScRegisterLink>
            </Pane>

            <Link to="/ozellikler">
              <span>Tüm Özellikleri İncele</span>
              <Icon
                icon="arrow-right"
                marginLeft="0.5rem"
                color={theme.palette.neutral.light}
                size={20}
              />
            </Link>
          </Pane>
        </Grid>
      </ScSpotlight>
    )
  }
}

export default SpotlightDark
