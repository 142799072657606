import React from 'react'

const IconFacebook = props => (
  <svg height="1em" viewBox="0 0 56.693 56.693" width="1em" {...props}>
    <path
      d="M40.43 21.739h-7.645v-5.014c0-1.883 1.248-2.322 2.127-2.322h5.395V6.125l-7.43-.029c-8.248 0-10.125 6.174-10.125 10.125v5.518h-4.77v8.53h4.77v24.137h10.033V30.269h6.77l.875-8.53z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default IconFacebook
