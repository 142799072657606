import React from 'react'

const IconMedium = props => (
  <svg viewBox="-91 49.217 56.693 56.693" {...props} width="1em" height="1em">
    <path
      d="M-37.87 64.672h-1.968c-.731 0-1.764 1.054-1.764 1.728v24.458c0 .676 1.033 1.596 1.764 1.596h1.968v5.806h-17.831v-5.806h3.732V66.745h-.183l-8.715 31.515h-6.747l-8.603-31.515h-.218v25.71h3.732v5.805H-87.63v-5.805h1.912c.787 0 1.82-.921 1.82-1.597V66.4c0-.674-1.033-1.728-1.82-1.728h-1.912v-5.806h18.667l6.129 22.807h.169l6.185-22.807h18.61v5.806z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default IconMedium
