import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
  Pane,
  TextInput,
  Button,
  Heading,
  Paragraph,
  toaster,
} from '@muhsis/ui'
import { Grid } from 'react-styled-flexboxgrid'

import { theme } from '../../theme'
import { sendMembershipRequest } from '../../services'

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .email('Geçersiz email.')
    .required('Bu alan boş bırakılamaz.'),
})

const ScSpotlight = styled.section`
  text-align: center;
  padding: 3rem 0 4rem;
  margin: 0;
`

// Replace this with validation error element from @muhsis/ui
const ScErrorMessage = styled.span`
  border-radius: 0.25rem;
  display: inline-block;
  background-color: ${theme.palette.red.base};
  color: #fff;
  font-size: 0.875rem;
  position: absolute;
  left: 10px;
  bottom: -18px;
  padding: 0 0.5rem;
`

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Fragment>
    <TextInput
      name="email"
      id="email"
      marginRight={5}
      width={250}
      height={38}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <ScErrorMessage>{errors[field.name]}</ScErrorMessage>
    )}
  </Fragment>
)

class Spotlight extends Component {
  state = {
    email: '',
    isLoading: false,
  }

  async handleFormSubmit(values, actions) {
    this.setState({ isLoading: true })
    try {
      const result = await sendMembershipRequest(values.email)
      this.setState({ isLoading: false, email: '' })
      actions.resetForm({ email: '' })

      // Continue if there's no api rate limiting
      if (result.data.StatusMessageType === 'Success') {
        toaster.success('Başarılı!', {
          description: `Muhsis'e üye olabilmeniz için size bir e-posta gönderdik. E-postadaki linke tıklayarak üyeliğinizi tamamlayabilir ve Muhsis'i hemen kullanmaya başlayabilirsiniz.`,
          duration: 10,
        })
      } else {
        toaster.danger('Hata!', {
          description: result.data.Message[0],
          duration: 10,
        })
      }
    } catch (err) {
      if (err) {
        toaster.warning('Çok Fazla İstek!', {
          description:
            'Kısa süre içerisinde çok fazla istekte bulundunuz. İstekte bulunmadan önce lütfen bir süre bekleyiniz.',
          duration: 10,
        })
        this.setState({ isLoading: false, email: '' })
        actions.resetForm({ email: '' })
      }
    }
  }

  render() {
    return (
      <ScSpotlight>
        <Grid>
          <Pane maxWidth={500} marginX="auto">
            <Heading size={900} marginBottom="1rem" fontWeight="700">
              Web Tabanlı Ön Muhasebe & Finans Yönetimi
            </Heading>
            <Paragraph size={500} marginBottom="2rem">
              Muhsis, iyi tasarlanmış, kullanımı kolay ve hızlı arayüzü ile
              beraber, şirketinizin ön muhasebe ve finans süreçlerini yöneterek,
              işinizi kolaylaştırır ve size zaman kazandırır.
            </Paragraph>

            <div>
              <Formik
                initialValues={{
                  email: this.state.email,
                }}
                enableReinitialize={true}
                validationSchema={SignupSchema}
                onSubmit={(values, actions) => {
                  this.handleFormSubmit(values, actions)
                }}
                render={props => (
                  <form onSubmit={props.handleSubmit}>
                    <Pane position="relative" display="inline-block">
                      <Field
                        name="email"
                        type="email"
                        placeholder="Email adresiniz.."
                        component={CustomInputComponent}
                        value={props.values.email}
                        disabled={this.state.isLoading}
                      />

                      <Button
                        type="submit"
                        appearance="primary"
                        intent="success"
                        isLoading={this.state.isLoading}
                        height={38}
                        top="-1px"
                      >
                        Ücretsiz Dene
                      </Button>
                    </Pane>
                  </form>
                )}
              />
            </div>
          </Pane>
        </Grid>
      </ScSpotlight>
    )
  }
}

export default Spotlight
