export { FeatureMain, FeatureItem } from './feature'
export { Header, HeaderAuth } from './header'
export {
  Spotlight,
  SpotlightDark,
  SpotlightLight,
  SpotlightHeading,
} from './spotlight'
export { PricingTable } from './pricing'
export { Footer } from './footer'
export { Support } from './support'
