import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { rgba, lighten } from 'polished'
import { Link } from 'gatsby'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Button, TextInput, Heading, Pane, Paragraph, Text } from '@muhsis/ui'

import { theme } from '../../theme'
import { BrandSymbol } from '../../components'
import {
  IconFacebook,
  IconLinkedin,
  IconMedium,
  IconTwitter,
} from '../../assets/icons'

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .email('Geçersiz email.')
    .required('Bu alan boş bırakılamaz.'),
})

// Replace this with validation error element from @muhsis/ui
const ScErrorMessage = styled.span`
  border-radius: 0.25rem;
  display: inline-block;
  background-color: ${theme.palette.red.base};
  color: #fff;
  font-size: 0.75rem;
  position: absolute;
  left: 10px;
  bottom: -15px;
  padding: 0 0.5rem;
`

// TODO@EMRE - Let's move this to somewhere common.
const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Fragment>
    <TextInput
      name="email"
      id="email"
      marginRight={5}
      width={200}
      height={32}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <ScErrorMessage>{errors[field.name]}</ScErrorMessage>
    )}
  </Fragment>
)

const ScFooter = styled.footer`
  border-top: 1px solid ${theme.colors.border.default};
  border-bottom: 5px solid ${lighten(0.05, theme.palette.primary.base)};
  padding: 4rem 0;
  margin: 0;
`

const ScFooterNav = styled.nav`
  & > a {
    color: ${theme.colors.text.muted};
    display: block;
    margin-bottom: 0.5rem;
    padding: 0;
    text-decoration: none;
    transition: color 0.15s, background-color 0.15s, padding-left 0.15s;
  }

  & > a:hover {
    color: ${theme.palette.primary.base};
    padding-left: 0.25rem;
  }
`

const ScNavDivider = styled.div`
  background-color: ${rgba(theme.palette.primary.base, 0.1)};
  height: 1px;
  margin: 1rem 0;
  max-width: 200px;
`

const ScSocialLink = styled.a`
  color: ${lighten(0.3, theme.colors.text.muted)};
  margin-right: 1.5rem;
  padding: 0.5rem 0;
  display: inline-block;
  text-decoration: none;
  transition: color 0.15s;

  &:hover {
    color: ${theme.palette.primary.base};
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }

  & > svg {
    height: 2rem;
    width: 2rem;
  }
`

class Footer extends Component {
  render() {
    return (
      <ScFooter>
        <Grid>
          <Row>
            <Col xs={4}>
              <BrandSymbol />
            </Col>

            <Col xs={4}>
              <ScFooterNav>
                <Heading size={400} marginBottom="2rem">
                  Menü
                </Heading>

                <Link to="/ozellikler">Özellikler</Link>
                <Link to="/fiyatlar">Fiyatlar</Link>
                <Link to="/guvenlik">Güvenlik</Link>
                <a
                  href="https://destek.muhsis.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Destek
                </a>

                <ScNavDivider />

                <Link to="/kullanici-sozlesmesi">Kullanıcı Sözleşmesi</Link>
                <Link to="/gizlilik-politikasi">Gizlilik Politikası</Link>
                <Link to="/cerez-politikasi">Çerez Politikası</Link>
              </ScFooterNav>
            </Col>

            <Col xs={4}>
              <Pane textAlign="right">
                <Heading size={600} marginBottom="1rem">
                  Mail Bültenimize Kaydolun
                </Heading>
                <Paragraph marginBottom="1.5rem" color="muted">
                  Mail bültenimize kayıt olarak Muhsis ile ilgili son
                  geliştirmelerden haberdar olabilirsiniz.
                </Paragraph>

                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={NewsletterSchema}
                  onSubmit={values => {
                    // same shape as initial values
                    console.log(values)
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Pane position="relative" display="inline-block">
                        <Field
                          name="email"
                          type="email"
                          placeholder="Email adresiniz.."
                          component={CustomInputComponent}
                        />

                        <Button
                          type="submit"
                          appearance="primary"
                          intent="warning"
                          height={32}
                          top="-1px"
                          iconAfter="envelope"
                        >
                          Kayıt Ol
                        </Button>
                      </Pane>
                    </Form>
                  )}
                </Formik>

                <Pane marginTop="1.5rem">
                  <ScSocialLink
                    href="https://www.linkedin.com/company/muhsis/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconLinkedin />
                  </ScSocialLink>

                  <ScSocialLink
                    href="https://twitter.com/muhsisbt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconTwitter />
                  </ScSocialLink>

                  <ScSocialLink
                    href="https://medium.com/@muhsis"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconMedium />
                  </ScSocialLink>

                  <ScSocialLink
                    href="https://www.facebook.com/muhsisbt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconFacebook />
                  </ScSocialLink>
                </Pane>

                <Pane marginTop="1rem">
                  <Text color="#999">Muhsis A.Ş. &copy; 2019</Text>
                </Pane>
              </Pane>
            </Col>
          </Row>
        </Grid>
      </ScFooter>
    )
  }
}

export default Footer
